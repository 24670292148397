import { Component, Input, OnInit } from '@angular/core';
import { Viewpoint } from '@models/viewpoint.model';

@Component({
  selector: 'app-horizontal-toolbar',
  templateUrl: './horizontal-toolbar.component.html',
  styleUrls: ['./horizontal-toolbar.component.scss']
})
export class HorizontalToolbarComponent implements OnInit {
  @Input ('justifyLeft') justifyLeft: boolean = false;
  @Input ('toggleBtnTop') toggleBtnTop: number = 87;
  @Input ('title') title: string;
  @Input ('viewpoint') currentViewpoint: Viewpoint;
  @Input ('viewpoints') viewpoints: Viewpoint[];
  isAB: boolean = true;
  isA: boolean = true;
  isShow: boolean = false;

  constructor() {}

  ngOnInit(): void {
  }

  toggleAB(){
    this.isA = !this.isA;
  }

  toggle(){
    this.isShow = !this.isShow;
  }

}
