<svg
  *ngIf="isDark"
  (click)="toggle()"
  [ngStyle]="{ top: top + 'px' }"
  class="dark-mode"
  width="27"
  height="27"
  viewBox="0 0 27 27"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g filter="url(#filter0_d)">
    <path
      d="M14 7.875C12.1367 7.875 10.625 9.42188 10.625 11.25C10.625 13.1133 12.1367 14.625 14 14.625C15.8281 14.625 17.375 13.1133 17.375 11.25C17.375 9.42188 15.8281 7.875 14 7.875ZM22.6484 10.7227L19.3086 9.07031L20.5039 5.51953C20.6445 5.0625 20.1875 4.60547 19.7305 4.74609L16.1797 5.94141L14.5273 2.60156C14.3164 2.14453 13.6484 2.14453 13.4375 2.60156L11.7852 5.94141L8.23438 4.74609C7.77734 4.60547 7.32031 5.0625 7.46094 5.51953L8.65625 9.07031L5.31641 10.7227C4.85938 10.9336 4.85938 11.6016 5.31641 11.8125L8.65625 13.4648L7.46094 17.0156C7.32031 17.4727 7.77734 17.9297 8.23438 17.7891L11.7852 16.5938L13.4375 19.9336C13.6484 20.3906 14.3164 20.3906 14.5273 19.9336L16.1797 16.5938L19.7305 17.7891C20.1875 17.9297 20.6445 17.4727 20.5039 17.0156L19.3086 13.4648L22.6484 11.8125C23.1055 11.6016 23.1055 10.9336 22.6484 10.7227ZM17.1641 14.4492C15.4062 16.207 12.5586 16.207 10.8008 14.4492C9.04297 12.6914 9.04297 9.84375 10.8008 8.08594C12.5586 6.32812 15.4062 6.32812 17.1641 8.08594C18.9219 9.84375 18.9219 12.6914 17.1641 14.4492Z"
      fill="white"
    />
  </g>
  <defs>
    <filter
      id="filter0_d"
      x="0.973633"
      y="0.258789"
      width="26.0176"
      height="26.0176"
      filterUnits="userSpaceOnUse"
      color-interpolation-filters="sRGB"
    >
      <feFlood flood-opacity="0" result="BackgroundImageFix" />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
      />
      <feOffset dy="2" />
      <feGaussianBlur stdDeviation="2" />
      <feColorMatrix
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
      />
      <feBlend
        mode="normal"
        in2="BackgroundImageFix"
        result="effect1_dropShadow"
      />
      <feBlend
        mode="normal"
        in="SourceGraphic"
        in2="effect1_dropShadow"
        result="shape"
      />
    </filter>
  </defs>
</svg>

<svg
  *ngIf="!isDark"
  [ngStyle]="{ top: top + 'px' }"
  (click)="toggle()"
  class="dark-mode"
  width="26"
  height="27"
  viewBox="0 0 26 27"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g filter="url(#filter0_d)">
    <path
      d="M13.9492 20.25C16.7266 20.25 19.2578 19.0195 20.9453 16.9453C21.1914 16.6289 20.9102 16.1719 20.5234 16.2422C16.1641 17.0859 12.1562 13.7461 12.1562 9.31641C12.1562 6.78516 13.4922 4.46484 15.707 3.19922C16.0586 2.98828 15.9531 2.49609 15.5664 2.42578C15.0391 2.32031 14.5117 2.28516 13.9492 2.25C8.95703 2.25 4.94922 6.29297 4.94922 11.25C4.94922 16.2422 8.95703 20.25 13.9492 20.25Z"
      fill="white"
    />
  </g>
  <defs>
    <filter
      id="filter0_d"
      x="0.949219"
      y="0.25"
      width="24.0885"
      height="26"
      filterUnits="userSpaceOnUse"
      color-interpolation-filters="sRGB"
    >
      <feFlood flood-opacity="0" result="BackgroundImageFix" />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
      />
      <feOffset dy="2" />
      <feGaussianBlur stdDeviation="2" />
      <feColorMatrix
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
      />
      <feBlend
        mode="normal"
        in2="BackgroundImageFix"
        result="effect1_dropShadow"
      />
      <feBlend
        mode="normal"
        in="SourceGraphic"
        in2="effect1_dropShadow"
        result="shape"
      />
    </filter>
  </defs>
</svg>
