import { Injectable, ElementRef, Output, EventEmitter } from '@angular/core';
import * as Marzipano from 'marzipano';
import { isDevMode } from '@angular/core';

/**
 * Service for managinf transitions of Marzipano scenes.
 */
@Injectable({
  providedIn: 'root',
})
export class TransitionService {
  private DEFAULT_YAW = 0;
  private DEFAULT_PITCH = -0.05;
  private DEFAULT_FOV = -0.05;
  private DEFAULT_VFOV_LIMIT = [0.698131111111111, 2.09439333333333];
  private DEFAULT_HFOV_LIMIT = [0.698131111111111, 2.09439333333333];
  private DEFAULT_PITCH_LIMIT = [-0.05, 0.18];
  public TRANSITION_DURATION = 200;
  public currentScene;
  private isAddDevTools: boolean = true; // click helper to find current yaw, pitch
  private scene: any;


  private readonly geometry = new Marzipano.CubeGeometry([
    {
      "tileSize": 256,
      "size": 256,
      "fallbackOnly": true
    },
    {
      "tileSize": 512,
      "size": 512
    },
    {
      "tileSize": 512,
      "size": 1024
    },
    {
      "tileSize": 512,
      "size": 2048
    },
    {
      "tileSize": 512,
      "size": 4096
    },
    {
      "tileSize": 512,
      "size": 8192
    }
  ]);

  /**
   * Create a Marzipano Scene object.
   * @param viewpoint 
   * @param viewer 
   * @param tilesPath 
   * @returns Marzipano scene
   */
  public readonly createMarzipanoScene = (
    viewpoint,
    viewer,
    tilesPath: string,
  ) => {
    const yaw = viewpoint.yaw || this.DEFAULT_YAW;
    const pitch = viewpoint.pitch || this.DEFAULT_PITCH;
    const fov = viewpoint.fov || this.DEFAULT_FOV;
    const vfovLimit = viewpoint.vfovLimit || this.DEFAULT_VFOV_LIMIT;
    const hfovLimit = viewpoint.hfovLimit || this.DEFAULT_HFOV_LIMIT;
    const pitchLimit = viewpoint.pitchLimit || this.DEFAULT_PITCH_LIMIT;
    const yawLimit = viewpoint.yawLimit;
    let limiters = [
      Marzipano.RectilinearView.limit.vfov(vfovLimit[0], vfovLimit[1]),
      Marzipano.RectilinearView.limit.hfov(hfovLimit[0], hfovLimit[1]),
      Marzipano.RectilinearView.limit.pitch(pitchLimit[0], pitchLimit[1]),
    ];
    if(yawLimit){
      limiters.push(Marzipano.RectilinearView.limit.yaw(yawLimit[0], yawLimit[1]));
    }
    const limiter = Marzipano.util.compose(...limiters);
    const source = Marzipano.ImageUrlSource.fromString(tilesPath);
    this.addDevTools(viewer);
    const view = new Marzipano.RectilinearView(
      {
        pitch: pitch,
        yaw: yaw,
        fov: fov,
      },
      limiter
    );
    return viewer.createScene({
      source,
      geometry: this.geometry,
      view: view,
      pinFirstLevel: true,
    });
  };

  /**
   * Displays yaw, pitch and fov values in console when screen is clicked on (in dev mode only).
   * @param viewer 
   * @returns 
   */
  private addDevTools(viewer) {
    return;
  }

  /**
   * Makes the new scene maintain the same yaw, pitch and fov as the previous scene.
   * @param currentScene 
   * @param nextScene 
   */
  private readonly maintainPointOfView = (currentScene, nextScene): void => {
    nextScene.view().setParameters({
      pitch: currentScene.view().pitch(),
      yaw: currentScene.view().yaw(),
      fov: currentScene.view().fov(),
    });
  };

  /**
   * Facilitates the switching betwen two scenes.
   * @param beforeScene 
   * @param afterScene 
   * @returns 
   */
  public readonly nextScene = (beforeScene, afterScene) => {
    switch (this.currentScene) {
      case beforeScene:
        this.maintainPointOfView(beforeScene, afterScene);
        return (this.currentScene = afterScene);
      case afterScene:
        this.maintainPointOfView(afterScene, beforeScene);
        return (this.currentScene = beforeScene);
      default:
        this.maintainPointOfView(afterScene, beforeScene);
        return (this.currentScene = afterScene);
    }
  };
}
