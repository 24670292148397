import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-dark-mode',
  templateUrl: './dark-mode.component.html',
  styleUrls: ['./dark-mode.component.scss'],
})
export class DarkModeComponent implements OnInit {
  isDark: boolean = false;
  @Input ('top') top: number = 87;
  @Output() toggleDarkScene = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}

  toggle() {
    this.isDark = !this.isDark;
    this.toggleDarkScene.emit(this.isDark);
  }
}
