<svg
  class="compass"
  width="84" height="84" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg"
  [ngStyle]="{transform: 'rotate(' + yaw + 'deg)'}"
>
  <g clip-path="url(#clip0_6754_92)">
  <path d="M3.67176e-06 42C5.69962e-06 18.804 18.8041 1.92979e-06 42 3.95764e-06C65.196 5.98549e-06 84 18.804 84 42C84 65.196 65.196 84 42 84C18.8041 84 1.6439e-06 65.196 3.67176e-06 42ZM75.6 42C75.6 23.4432 60.5568 8.40001 42 8.40001C23.4433 8.4 8.40001 23.4432 8.40001 42C8.4 60.5568 23.4433 75.6 42 75.6C60.5568 75.6 75.6 60.5568 75.6 42Z" fill="white"/>
  <path d="M42.5 13L51.5933 42.25L33.4068 42.25L42.5 13Z" fill="white"/>
  <path d="M50.2352 42.75L42.5 67.6315L34.7649 42.75L50.2352 42.75Z" stroke="white" stroke-width="2"/>
  <path d="M42.5 1.2C40.8432 1.2 39.5 2.54315 39.5 4.2C39.5 5.85685 40.8432 7.2 42.5 7.2C44.1569 7.2 45.5 5.85686 45.5 4.2C45.5 2.54315 44.1569 1.2 42.5 1.2Z" fill="#A4A1A1"/>
  </g>
  <defs>
  <clipPath id="clip0_6754_92">
  <rect width="84" height="84" fill="white" transform="translate(84 84) rotate(-180)"/>
  </clipPath>
  </defs>
  </svg>