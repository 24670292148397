<div class="switch-container">
  <label class="switch btn-color-mode-switch">
    <input
      type="checkbox"
      name="color_mode"
      id="color_mode"
      value="1"
      [checked]="checked"
      (change)="toggle()"
    />
    <label
      for="color_mode"
      data-on="Proposed"
      data-off="Existing"
      class="btn-color-mode-switch-inner"
    ></label>
  </label>
  <div
    *ngIf="viewpoint?.mode.proposed.layers"
    class="options"
    [ngClass]="this.checked ? 'isVisible' : ''"
  >
    <div
      *ngFor="let layer of viewpoint?.mode.proposed.layers.items"
      class="form-check"
    >
    <div *ngIf="!layer.isHidden">
      <input
      class="form-check-input"
      type="checkbox"
      [id]="layer.slug"
      [checked]="toggleState[layer.slug]?.checked"
      (change)="toggleLayer(layer.slug)"
    />
    <label
      class="form-check-label"
      [for]="layer.slug"
      [innerHTML]="layer.title"
    ></label>
    </div>
      
    </div>
  </div>
</div>
