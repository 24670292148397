import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-project-name-box',
  templateUrl: './project-name-box.component.html',
  styleUrls: ['./project-name-box.component.scss']
})
export class ProjectNameBoxComponent implements OnInit {

  @Input('projectName') projectName: string = '';
  
  constructor() { }

  ngOnInit(): void {
  }

}
