import { Component, Input, OnInit } from '@angular/core';
import { app } from '@shared/data/data.json';
@Component({
  selector: 'app-info-box',
  templateUrl: './info-box.component.html',
  styleUrls: ['./info-box.component.scss'],
})
export class InfoBoxComponent implements OnInit {

  private DEFAULT_TEXT: string = `The ${app.projectName} Project visual simulations have been produced using the most current and accurate photography, 3d modelling, survey, and terrain data available. While these simulations show the most likely level of visual impact from each observation point, changes to the final site layout, vegetation screening or facility specifications may result in different visual effects once the actual project is constructed.`;
  @Input('text') text: string = this.DEFAULT_TEXT;
  @Input('width') width: string = '700px';

  constructor() {}

  ngOnInit(): void {}
}
