import {
  Component,
  OnInit,
} from '@angular/core';
import { ViewpointsService } from '@shared/services/viewpoints.service';
import 'leaflet/dist/images/marker-shadow.png';
import 'leaflet-rotatedmarker';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit {
  private centerViewpointSlug = 'county-rd-200-s';
  mode: string = 'proposed';
  viewpoints: any[];
  viewpoint: any;

  constructor(private viewpointsService: ViewpointsService) {
    
  }

  ngOnInit(): void {
    this.viewpoints = this.viewpointsService.getViewpoints();
    this.viewpoint = this.viewpointsService.getViewpoint(
      this.centerViewpointSlug,
      false
    );
  }

  public toggleMode = (): void => {
    this.viewpointsService.toggleMode();
  };
}
