import { Component, OnDestroy } from '@angular/core';
import { ViewpointsService } from '@shared/services/viewpoints.service';
import { Subscription } from 'rxjs';
import { Viewpoint } from '@models/viewpoint.model';
import { app } from '@shared/data/data.json';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnDestroy {
  app: any;
  viewpoint: Viewpoint;
  changeViewpointSub: Subscription;

  constructor(private viewpointsService: ViewpointsService) { 
    this.app = app;
    this.changeViewpointSub = this.viewpointsService.onChangeViewpoint.subscribe((viewpoint) => {
      this.viewpoint = viewpoint;
    })
  }

  ngOnDestroy(): void {
    this.changeViewpointSub.unsubscribe();
  }

  

}
